import * as React from "react";
import Navbar from './navbar';

const Header = () => (
  <div className="center header col-sm-8">
    <div align="center" className={"site-header"}><font size="6"><strong>The Curran Index to Nineteenth-Century Periodicals</strong></font></div>
    <Navbar />
  </div>
)

export default Header
