import * as React from "react";
import { Link } from "gatsby";
import { Dropdown } from 'semantic-ui-react'

const Header = () => (
  <div className="col-sm-12 full-width-depad">
    <nav className="navbar nav-bg navbar-nav">
      <div>
        <Link className="nav-item nav-link" to="/">Home</Link> <br />

        <Dropdown className="nav-item nav-link" text="Browse">
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to="/periodicals/" text="Periodicals" />
            <Dropdown.Item as={Link} to="/contributors/" text="Contributors" />
          </Dropdown.Menu>
        </Dropdown>
        <br />

        <Link className="nav-item nav-link" to="/news/">News</Link> <br />

        <Dropdown className="nav-item nav-link" text="Contact">
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to="/contact/" text="Contact Us" />
            <Dropdown.Item as={Link} to="/submissions/" text="Submissions" />
          </Dropdown.Menu>
        </Dropdown>
        <br />
      </div>
    </nav>
  </div>
)

export default Header;
