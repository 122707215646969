import * as React from "react"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"
import 'semantic-ui-css/semantic.min.css'

const Layout = ({ children }) => (
  <body className="custom-background">
    <div id="wrapper" className="col-sm-8 white-background col-sm-offset-2 full-width-depad">
      <Header />
      <div className="contents repad">
        {children}
      </div>
      <Footer />
    </div>
  </body>
)

export default Layout
