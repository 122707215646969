import * as React from "react";
import { Link } from "gatsby";
import { Dropdown } from 'semantic-ui-react'
import { StaticImage } from "gatsby-plugin-image";

const Footer = () => (
  <footer className="footer">
    <div className="footer-links">
      <Dropdown className="nav-item nav-link" text="About">
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to="/history/" text="History" />
          <Dropdown.Item as={Link} to="/attribution-scholarship/" text="Attribution Scholarship" />
          <Dropdown.Item as={Link} to="/user-guide/" text="User Guide" />
        </Dropdown.Menu>
      </Dropdown>
      <br />

      <Link className="nav-item nav-link" to="/related-sites/">Related Sites</Link> <br />
    </div>
    <StaticImage src="../images/oldbooks2.jpg" alt="RSVP logo" />
    <div className="footer_copyright">The Curran Index, ©2017  All Rights Reserved</div>
  </footer>
)

export default Footer
